/**
 * NavBar component
 *
 * Returns a Navbar on left of screen as a column flex container
 */

import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"

import logo from "../images/logo.png"
import { to_mobile_view } from "../constants"

const FlexNav = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 10vw;
  min-width: 10rem;
  overflow-y: hidden;
  background-color: #222222;

  @media screen and (max-width: ${to_mobile_view}) {
    height: 7%;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: stretch;
  }
`
const Logo = styled.img`
  width: 30%;
  height: auto;
  margin: 0;

  @media screen and (max-width: ${to_mobile_view}) {
    padding: 0.5em;
    height: 100%;
    width: auto;
  }
`

const Title = styled.h1`
  margin-top: 0.2rem;
  margin-bottom: 3rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: #ebebeb;
  font-family: monospace;

  @media screen and (max-width: ${to_mobile_view}) {
    margin: 0;
    font-size: 1rem;
    display: none;
  }
`
const LogoAndTitle = styled(Link)`
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding-top: 2em;

  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: ${to_mobile_view}) {
    width: 20vw;
    padding: 0;
    margin-left: 1em;
    display: none;
  }
`

const NavList = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow-y: auto;

  @media screen and (max-width: ${to_mobile_view}) {
    height: 100%;
    width: auto;
    flex-direction: row;
    margin-left: 1.5em;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    flex-grow: 2;
    justify-content: space-evenly;
  }
`

const NavItem = styled(Link)`
  margin: 0.5em 0;
  color: #ebebeb;
  width: 100%;
  text-align: center;
  overflow-y: hidden;
  flex-shrink: 0;
  text-decoration: none;

  @media screen and (max-width: ${to_mobile_view}) {
    width: auto;
    flex-shrink: 0;
    margin: 0.5em 0.5em;
  }
`

const NavBar = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <FlexNav>
      <LogoAndTitle to="/">
        {/* <Logo alt={"Website l ogo"} src={logo} /> */}
        <Title>{data.site.siteMetadata.title}</Title>
      </LogoAndTitle>
      <NavList>
        <NavItem activeStyle={{ color: "grey" }} to="/">
          Home
        </NavItem>
        <NavItem activeStyle={{ color: "grey" }} to="/about">
          About
        </NavItem>
        <NavItem activeStyle={{ color: "grey" }} to="/projects">
          Projects
        </NavItem>
        {/* <NavItem activeStyle={{'color':'black'}} to="/blog">Blog</NavItem> */}
      </NavList>
    </FlexNav>
  )
}

export default NavBar
