/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import NavBar from "./navbar"
import "./layout.css"
import { to_mobile_view } from "../constants"

const FrameContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  overflow-y: hidden;

  @media screen and (max-width: ${to_mobile_view}) {
    flex-direction: column;
  }
`

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow-y: auto;

  @media screen and (max-width: ${to_mobile_view}) {
    height: 90vh;
    width: 100%;
  }
`

const Footer = styled.p`
  margin-bottom: 0;
  background-color: #ffeddb;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query buildDateQuery {
      siteBuildMetadata {
        buildTime(formatString: "YYYY")
      }
    }
  `)

  return (
    <FrameContainer>
      <NavBar />
      <MainContainer>
        {children}
        <Footer>© {data.siteBuildMetadata.buildTime} iangohy</Footer>
      </MainContainer>
    </FrameContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
