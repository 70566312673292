/**
 * Sections that will partition the MainComponent
 *
 * Expects title prop
 * Expects props children to populate the data within the section
 * Background color set by props backgroundColor
 * Height set by props height
 * Scroll or not set by props overflowY
 */

import React from "react"
import styled from "styled-components"
import { to_mobile_view } from "../constants"

const Title = styled.h1`
  font-weight: bold;
`

const StyledSection = styled.div`
  box-sizing: border-box;
  overflow-y: ${props => props.overflowY || "hidden"};
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  height: ${props => props.height || "60vh"};
  text-align: center;
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
  background-color: ${props => props.backgroundColor || "white"};

  @media screen and (max-width: ${to_mobile_view}) {
    padding-left: 1em;
    padding-right: 1em;
  }
`

const Section = ({
  children,
  backgroundColor,
  id,
  height,
  overflowY,
  title,
}) => {
  return (
    <StyledSection
      id={id}
      height={height}
      backgroundColor={backgroundColor}
      overflowY={overflowY}
      title={title}
    >
      {title && <Title>{title}</Title>}
      {children}
    </StyledSection>
  )
}

export default Section
